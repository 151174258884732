.hero{
    position: relative; 
    background-image: url('../../../assets/img/slide/slide-1.jpg'); 
    background-size: cover; background-position: center;
}

.hero .full-height {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;    /* Center vertically */
    height: 100vh;          /* Full viewport height */
  }

.hero .Overlay{
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
    background: rgba(0, 0, 0, 0.5); 
    z-index: 1;
}

.hero .Container{
    position: relative; 
    height: 100vh; 
    z-index: 2;
}

.counts{
    visibility: visible; 
    animation-name: fadeIn;
}

.countsFadeLeft{
    visibility: visible; 
    animation-name: fadeInLeft;
}

.countsFadeUp{
    visibility: visible; 
    animation-name: fadeInUp;
}

.countsFadeRight{
    visibility: visible; 
    animation-name: fadeInRight;
}

.whyUs{
    background-image: url("../../../assets/img/why-us.jpg");
}
