.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom styles for the navbar */
#navbar {
  background-color: #f8f9fa; /* Set your desired background color */
  padding: 10px 0; /* Adjust the vertical padding to make it smaller */
}

#navbar ul {
  list-style-type: none; /* Remove bullets from list items */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
  display: flex; /* Use flexbox to center items */
  align-items: center; /* Center items vertically */
}

#navbar li {
  margin: 0 15px; /* Add horizontal spacing between items */
}

/* Align the last nav item to the right */
#navbar .nav-item-right {
  margin-left: auto; /* Push this item to the right */
}

#navbar .nav-link {
  display: block; /* Make the links block elements */
  text-align: center; /* Center the text inside the links */
  padding: 5px 10px; /* Adjust padding to reduce height */
  transition: background-color 0.3s; /* Add transition for hover effect */
}

#navbar .nav-link:hover {
  background-color: #e2e6ea; /* Optional: Change background color on hover */
}

.mobile-nav-toggle {
  display: none; /* Hide mobile toggle by default, show with media query */
}

/* Media query for mobile */
@media (max-width: 768px) {
  .mobile-nav-toggle {
    display: block; /* Show mobile toggle */
  }

  #navbar ul {
    flex-direction: column; /* Stack items vertically on mobile */
  }
}

.loan-banner {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  text-align: center;
  position: relative;
}

.loan-banner img {
  max-width: 150px;
  margin-bottom: 15px;
}

.loan-details {
  margin-bottom: 15px;
}

.loan-amount,
.loan-term,
.loan-interest {
  font-size: 1.2rem;
  margin: 5px 0;
}
.Heading_text {
  transition: none 0s ease 0s;
  text-align: inherit;
  line-height: 65px;
  border-width: 0px;
  margin: 0px;
  padding: 0px;
  letter-spacing: 0px;
  font-size: 3.8rem !important;
  font-weight: 100 !important;
}

.Heading_text2 {
  transition: none 0s ease 0s;
  text-align: inherit;
  line-height: 65px;
  border-width: 0px;
  margin: 0px;
  padding: 0px;
  letter-spacing: 0px;
  font-size: 3.8rem !important;
  font-weight: 100 !important;
}

.top-deal-ribbon {
  position: absolute;
  top: 10px;
  right: -20px;
  background-color: #ed502e; /* Ribbon color */
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px 15px;
  transform: rotate(45deg);
  z-index: 10;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
